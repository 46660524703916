import React from 'react';
import './Header.css'; // Importa el archivo CSS aquí

const Header = () => {
  return (
    <header className="appHeader">
      <h1>ASERTIS BPO-IA</h1>
      {/* Agrega aquí cualquier otro contenido que quieras en el encabezado */}
    </header>
  );
};

export default Header;