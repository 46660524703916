// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ChatComponent from './ChatComponent';
import { ReactComponent as LogoAI } from './img/LogoAI.svg';
import Header from './Header';
import TypingText from './TypingText';

const App = () => {

  const searchParams = new URLSearchParams(window.location.search);
  const context = searchParams.get('context') || ''; // Usar un string vacío como valor predeterminado

  return (
    <Router>
      <div className="appContainer">
        <Header /> 
        <LogoAI className="logo" />
        <TypingText 
        text={`Biienvenido a Asertis BPO-IA. Como su Copilot, estoy aquí para facilitar la optimización y el éxito en la gestión de operaciones de ${context}.`} 
        typingSpeed={20} 
      />
        <Routes>
          <Route path="/fundacionwwb" element={<ChatComponent />} />
          <Route path="/expomotor" element={<ChatComponent />} />
          <Route path="/asertisbpoia" element={<ChatComponent />} />
        </Routes>
        <footer className="appFooter">
          © 2024 Asertis BPO, Developer alejandromarin@fenalcovalle.com
        </footer>
      </div>
    </Router>
  );
};

export default App;
